<template lang="pug">
	.info-tooltip
		span.b1.text-bold.d-block.info-tooltip__subtitle {{ $t('user.title') }}
		.info-tooltip__item
			span.b3.text-grey(ref='id') {{ $t('project.id') }}:
			span.b3.ml-1(v-if="item.botViewUser" ref='id_value' v-html="item.botViewUser.id")
			span.text-warning.ml-1(v-else ref='id_value') {{ $t('user.unknow') }}
		.info-tooltip__item
			span.b3.text-grey(ref='dateCreation') {{ $t('user.dateCreation') }}:
			span.b3.ml-1(ref='dateCreation_value') {{ item.createdAt | dateDDMMMYYYY }}
		.info-tooltip__item(v-if="item.email")
			span.b3.text-grey(ref='email') {{ $t('user.email') }}:
			span.b3.ml-1(ref='email_value' v-html="item.email")
		.info-tooltip__item(v-if='item.botViewUser')
			span.b3.text-grey(ref='telegramID') {{ $t('user.telegramID') }}:
			span.b3.ml-1(ref='telegramID_value' v-html="item.botViewUser.tgId")
		.info-tooltip__item(v-if='item.botViewUser')
			span.b3.text-grey(ref='telegramUsername') {{ $t('user.telegramUsername') }}:
			span.b3.ml-1(ref='telegramUsername_value' v-html="item.botViewUser.tgUsername")
		.info-tooltip__item(v-if="item.botViewUser")
			span.b3.text-grey(ref='balance1') 💎 {{ $t('finance.balance') }}:
			span.b3.ml-1(ref='balance1_value') {{ item.botViewUser.balanceDiamonds }}

		.info-tooltip__wrap
			span.b1.text-bold.d-block.info-tooltip__subtitle {{ $t('project.bot') }}
			.info-tooltip__item
				span.b3.text-grey(ref='status') {{ $t('bot.status') }}:
				span.b3.ml-1.info-tooltip__status(v-if="!item.botView" ref='status_value') {{ $t('bot.notActivated') }}
				span.b3.ml-1.info-tooltip__status.is-active(v-if="item.botView && item.botView.isActive()" ref='status_value') {{ $t('bot.statusActive') }}
				span.b3.ml-1.info-tooltip__status(v-if="item.botView && !item.botView.isActive()" ref='status_value') {{ $t('bot.statusInactive') }}

		.info-tooltip__wrap(v-if='item && item.botViewUser && item.botViewUser.subscriptions')
			span.b1.text-bold.d-block.info-tooltip__subtitle {{ $t('finance.subscriptions') }}
			.border-rounded.mb-1.info-tooltip__subscription(v-for="(subscription, i) in subscriptions" :key="`subscriptions-${i}`" :ref="setItemRef")
				.info-tooltip__item
					span.b3.text-grey(:class='`subId${i}`') {{ $t('project.id') }}:
					span.b3.ml-1(:class='`subId${i}_value`') {{ subscription.id }}
				.info-tooltip__item
					span.b3.text-grey(:class='`subStatus${i}`') {{ $t('project.status') }}:
					span.b3.ml-1(:class="[{'text-success is-active': subscription.isActive() && subscription.plan && subscription.plan.duration,'text-primary': subscription.isStatusTrial(), 'text-grey mb-2': subscription.isCompleted() || subscription.isCancelledButNotKicked() || subscription.isCompletedSubscription()}, `subStatus${i}_value`]" v-html="status(subscription)")
				.info-tooltip__inner(:class="{'expand-text': expand == subscription.id}")
					.info-tooltip__item
						span.b3.text-grey(:class='`tariff${i}`') {{ $t('plan.title') }}:
						span.b3.ml-1(v-if="subscription.plan && subscription.plan.title" :class='`tariff${i}_value`') {{ subscription.plan.title == 'Yearly subscription' ? $t('date.year') : $t('date.month') }}
					.d-flex.flex-wrap.info-tooltip__item
						span.b3.text-grey.mr-1(:class='`price${i}`') {{ $t('forms.price') }}:
						template
							span.b3.mr-2.text-nowrap(v-if="subscription.price" :class='`price${i}_value`' v-html="subscription.price.priceWithCurrency()")
							span.b3.mr-2.text-grey.text-through.text-nowrap(v-if='subscription.promotion || subscription.promoCode' :class='`price${i}_value`' v-html="subscription.plan.price.priceWithCurrency()")
							span.mr-2.info-tooltip__discount(v-if='subscription.promotion' :class='`price${i}_value`') -{{subscription.promotion.discount}}%
							span.mr-2.info-tooltip__discount(v-if='subscription.promoCode' :class='`price${i}_value`') -{{subscription.promoCode.discount}}%
							span.text-success.info-tooltip__discount(v-if='subscription.extraPrice' :class='`price${i}_value`' v-html='`+${subscription.extraPrice.priceWithCurrency()}`')
					.info-tooltip__item
						span.b3.text-grey.mr-1(:class='`paidTo${i}`') {{ $t('project.paidTo') }}:
						span(:class='`paidTo${i}_value`')
							span.b3(v-if='subscription.kickAt') {{ subscription.kickAt | dateDDMMMYYYY}}
							span.b3(v-else-if='subscription.kickedAt') {{subscription.kickedAt | dateDDMMMYYYY}}
							span.b3(v-else-if='subscription.nextAt') {{ subscription.nextAt | dateDDMMMYYYY}}
							span.b3(v-else-if='subscription.plan && subscription.plan.isForever()') {{ $t('plan.periods.forever')}}
					.info-tooltip__item
						span.b3.text-grey.text-nowrap.mr-1(:class='`next${i}`') {{ $t('project.nextPayment') }}:
						.d-flex(:class='`next${i}_value`')
							span.b3(v-if='(subscription.nextAt && !subscription.isOnSupportCriticalAndUnknown()) && !subscription.isOneTime()') {{ subscription.nextAt | dateDDMMMYYYY }}
							span.b3(v-if='subscription.isOnSupport() || subscription.isOnSupportCriticalAndUnknown()' v-html="$t('plan.nextPaymentInSupport')")
							span.b3.mb-0.info-tooltip__tooltip(v-if='subscription.isOnSupport() || subscription.isOnSupportCriticalAndUnknown()' :id="!isMobile ? `nextPaymentNull-${subscription.id}` : ''" @click="isMobile ? $bvModal.show(`nextPaymentNull-${subscription.id}`) : ''") ?
								.info-tooltip__above-tooltip(v-if="!isMobile")
									p.b3.mb-3(v-html="$t('tutorials.recoverySubscriptionDesc')")
									pw-btn-link(:text="`<div class='status-dollar-circle bg-white mr-1'></div>&nbsp${$t('tutorials.recoverySubscription')}`" :href="'https://docs.paywall.pw/rus/obshee/vosstanovlenie-podpisok'" :blank="true" :tooltip="true")
							span.b3.mb-0(v-if='subscription && subscription.isCompletedSubscription() || subscription.nextAt == null && subscription.hasPaymentPaid() && !subscription.isOnSupport() || subscription.isOneTime() || subscription.isOneTimeAudience() && !subscription.nextAt') {{ $t('plan.nextPaymentNull') }}
					.info-tooltip__item
						span.b3.text-grey(:class='`paymentsCount${i}`') {{ $t('project.paymentsCount') }}:
						span(:class='`paymentsCount${i}_value`')
							span.b3.ml-1(v-html="subscription.counters.count")
							span.b3.text-primary.ml-2.info-tooltip__link(v-if='subscription.counters && subscription.counters.count' @click="goToPayments") {{ $t('project.show') }}
					div
						.b3.info-tooltip__item(v-if='subscription.counters && subscription.getProfitMerchatOtherValue() != 0')
							span.mr-1 🇷🇺
							span.text-grey(:class='`profit${i}`') {{ `${$t('project.profit')}` }}:
							span(:class='`profit${i}_value`' v-html="` ${subscription.getProfitMerchatOther()}`")
						.b3.info-tooltip__item(v-if='subscription.counters && subscription.getProfitMerchatWorld2ruValue() != 0')
							span.mr-1 🌍
							span.text-grey(:class='`profit${i}`') {{ `${$t('project.profit')}` }}:
							span(:class='`profit${i}_value`' v-html="` ${subscription.getProfitMerchatWorld2ru()}`")
						.b3.info-tooltip__item(v-if='subscription.counters && subscription.getProfitMerchatOtherValue() == 0 && subscription.getProfitMerchatWorld2ruValue() == 0')
							span(v-html="`${subscription.price.currency == 'RUB' ? '🇷🇺' : '🌍'}`")
							span.text-grey(:class='`profit${i}`') {{ ` ${$t('project.profit')}` }}:
							span(:class='`profit${i}_value`' v-html="` 0&nbsp;${currency[subscription.price.currency]}`")
					.info-tooltip__item
						span.b3.text-grey(:class='`tokens${i}`') ⭐ {{ $t('finance.balance') }}:
						span.b3.ml-1(:class='`tokens${i}_value`') {{ subscription.tokens }}

				.btn.btn-expand(@click="doExpand(subscription.id)" :class="{'rotate': expand == subscription.id}") {{ expand != subscription.id ? $t('forms.expand') : $t('forms.collapse') }}

		.btn.btn-border.btn-small.info-tooltip__copy(@click='copy') {{ $t('button.copyData') }}
</template>

<script>
import PwBtnLink from '@/components/Buttons/PwBtnLink';
import { SERVICE_STRING } from '@/utils/string';
import { CURRENCY } from '@/models/money';
import { Subscription } from '@/models/subscription';

export default {
	name: 'InfoTooltip',
	components: {
		PwBtnLink
	},
	data: () => ({
		expand: null,
		itemRefs: []
	}),
	props: {
		item: {
			type: Object,
			default: (() => {})
		}
	},
	computed: {
		currency() {
			return CURRENCY;
		},
		subscriptions() {
			return _.map(this.item.botViewUser.subscriptions, i => i instanceof Subscription ? i : new Subscription(i));
		}
	},
	methods: {
		status(item) {
			//if( item.isUndefined() ) return this.$t('statuses.unknown');
			if( item.isStatusTrial() ) return this.$t('statuses.trial');
			if( item.isActive() && item.plan.duration ) return this.$t('statuses.active');
			if( item.isCompleted() || item.isCancelledButNotKicked() || item.isCompletedSubscription() ) return this.$t('statuses.closed');
			if( item.plan && !item.plan.duration) return this.$t('statuses.withoutSubscription');

			return '';
		},
		paymentsAmountRu(v) {
			return v + ' ' + SERVICE_STRING.ru_ends(v, this.$t('project.paymentsArray'));
		},
		goToPayments() {
			this.$router.push({name: 'finance', query: {type: 'payments', search: this.item.id}});
		},
		copy() {
			let text = [];
			let id = [];
			let value = [];

			_.each(this.$refs, (r, i) => i.includes('_value') ? value.push(r.innerText) : id.push(r.innerText));

			this.itemRefs.forEach((el, i) => {
				id.push(`${this.$t('project.statusSub.new')}-${i + 1}`.toUpperCase());
				value.push('');

				id.push(el.querySelector(`.subId${i}`).innerText);
				value.push(el.querySelector(`.subId${i}_value`).innerText);

				id.push(el.querySelector(`.subStatus${i}`).innerText);
				value.push(el.querySelector(`.subStatus${i}_value`).innerText);

				id.push(el.querySelector(`.tariff${i}`).innerText);
				value.push(el.querySelector(`.tariff${i}_value`).innerText);

				id.push(el.querySelector(`.price${i}`).innerText);
				value.push(el.querySelector(`.price${i}_value`).innerText);

				id.push(el.querySelector(`.paidTo${i}`).innerText);
				value.push(el.querySelector(`.paidTo${i}_value span`).innerText);

				id.push(el.querySelector(`.next${i}`).innerText);
				value.push(el.querySelector(`.next${i}_value span`).innerText);

				id.push(el.querySelector(`.paymentsCount${i}`).innerText);
				value.push(el.querySelector(`.paymentsCount${i}_value span`).innerText);

				id.push(el.querySelector(`.profit${i}`).innerText);
				value.push(el.querySelector(`.profit${i}_value`).innerText);

				id.push(el.querySelector(`.tokens${i}`).innerText);
				value.push(el.querySelector(`.tokens${i}_value`).innerText);
			});

			_.each(id, (v, i) => text.push(`${v} ${value[i]}`));

			this.$copyText(text.join('\n'))
				.then( e => this.$notify("success", this.$t('success.dataCopied')))
				.catch( e => this.$notify("error", this.$t('error.dataCopied'), e));
		},
		doExpand(id) {
			if (this.expand != id) {
				this.expand = id;
			} else {
				this.expand = null;
			}
		},
		setItemRef(el) {
			if (el)
				this.itemRefs.push(el)
		}
	}
}
</script>
